/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:333ee46a-7614-4c1b-a159-b2f494eaf5a9",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_v7cK4SZwl",
    "aws_user_pools_web_client_id": "2d9p70u17otbshja7t544r1pmm",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "personalphotoappstore184928-ekprod",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
