import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);

Amplify.configure({
  API: {
    aws_project_region: "us-east-1",
    aws_appsync_graphqlEndpoint: "https://kfauum4ngfbudlqvxxwinjdi4a.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    aws_cognito_identity_pool_id: "us-east-1:333ee46a-7614-4c1b-a159-b2f494eaf5a9",
    aws_cognito_region: "us-east-1",
    aws_user_pools_id: "us-east-1_v7cK4SZwl",
    aws_user_pools_web_client_id: "2d9p70u17otbshja7t544r1pmm"
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <title>EAK Albums</title>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
