// components/ProtectSecond.js
import {
  useAuthenticator, Heading, Card, Image, View, Link, Flex, Badge, Text, Button, Collection, Divider, useTheme
} from '@aws-amplify/ui-react';
import { API, Storage } from 'aws-amplify';
import { useParams, useNavigate, } from "react-router-dom";
import { querySubPhotoAlbumList, queryNonChildPhotoAlbumList, getPhotoPostsByPhotoAlbumId, queryPhotoAlbumById } from '../graphql/customQueries';
import { deletePhotoPost as deletePhotoPostMutation, deletePhotoAlbum as deletePhotoAlbumMutation } from '../graphql/customMutations';
import React, { useEffect, useState, } from 'react';
import styles from '../css/image.module.css';
import FullscreenView from './FullscreenView';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { useMediaQuery } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import { CenterFocusStrong } from '@mui/icons-material';



export function ProtectedSecond() {
  const { route } = useAuthenticator((context) => [context.route]);

  const message =
    route === 'authenticated' ? ViewPhotoAlbums() : 'Loading...';
  return <Heading level={1}>{message}</Heading>;
}

function ViewPhotoAlbums() {
  const navigate = useNavigate();

  const { tokens } = useTheme();
  const [photoAlbums, setPhotoAlbums] = useState([]); 
  const [photoAlbum, setPhotoAlbum] = useState([]);
  const params = useParams();
  const [photoPosts, setPhotoPosts] = useState([]);

  async function fetchPhotoAlbum() {
    if (params.id && params.id != 'home') {
      const apiData = await API.graphql({ query: queryPhotoAlbumById, variables: { id: params.id } });

      setPhotoAlbum(apiData.data.getPhotoAlbum);
    }
    else
    {
      setPhotoAlbum([]);
    }
  }

  async function fetchPhotoPosts() {
    if (typeof params.id !== 'undefined' &&  params.id != 'home') {
      const apiData = await API.graphql({ query: getPhotoPostsByPhotoAlbumId, variables: { id: params.id } });
      const photoPostsFromAPI = apiData.data.listPhotoPosts.items;
      photoPostsFromAPI.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
      await Promise.all(photoPostsFromAPI.map(async photoAlbum => {
        if (photoAlbum.photoImageName) {
          const image = await Storage.get("tn-" + photoAlbum.photoImageName);
          photoAlbum.photoImageLocation = image;
        }
        return photoAlbum;
      }))

      setPhotoPosts(photoPostsFromAPI);
    }
    else
    {
      setPhotoPosts([]);
    }
  }

  async function fetchPhotoAlbums() {
    if (typeof params.id !== 'undefined' && params.id != 'home') {
      const apiData = await API.graphql({ query: querySubPhotoAlbumList, variables: { id: params.id } });
      const photoAlbumsFromAPI = apiData.data.listPhotoAlbums.items;
      await Promise.all(photoAlbumsFromAPI.map(async photoAlbum => {
        if (photoAlbum.photoAlbumCoverImageName) {
          const image = await Storage.get("tn-" + photoAlbum.photoAlbumCoverImageName);
          photoAlbum.photoAlbumCoverImageLocation = image;
        }
        return photoAlbum;
      }))
      photoAlbumsFromAPI.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      setPhotoAlbums(photoAlbumsFromAPI);
    }
    else {
      const apiData = await API.graphql({ query: queryNonChildPhotoAlbumList });
      const photoAlbumsFromAPI = apiData.data.listPhotoAlbums.items;
      await Promise.all(photoAlbumsFromAPI.map(async photoAlbum => {
        if (photoAlbum.photoAlbumCoverImageName) {
          const image = await Storage.get("tn-" + photoAlbum.photoAlbumCoverImageName);
          photoAlbum.photoAlbumCoverImageLocation = image;
        }
        return photoAlbum;
      }))
      photoAlbumsFromAPI.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      setPhotoAlbums(photoAlbumsFromAPI);
    }
  }

  async function deletePhotoPost({ id }) {
    const newPhotoPostsArray = photoPosts.filter(photoPost => photoPost.id !== id);
    const deletedPhotoPostsArray = photoPosts.filter(photoPost => photoPost.id === id);
    setPhotoPosts(newPhotoPostsArray);
    await API.graphql({ query: deletePhotoPostMutation, variables: { input: { id } } });
    //alert(deletedPhotoPostsArray[0].photoImageName);
    await Storage.remove(deletedPhotoPostsArray[0].photoImageName);
    await Storage.remove("tn-" + deletedPhotoPostsArray[0].photoImageName);
  }

  async function deletePhotoAlbum({ id }) {

    const newPhotoAlbumsArray = photoAlbums.filter(photoAlbum => photoAlbum.id !== id);
    const deletedPhotoAlbumsArray = photoAlbums.filter(photoAlbum => photoAlbum.id === id);
    setPhotoAlbums(newPhotoAlbumsArray);
    await API.graphql({ query: deletePhotoAlbumMutation, variables: { input: { id } } });
    //alert(deletedPhotoPostsArray[0].photoImageName);
    await Storage.remove(deletedPhotoAlbumsArray[0].photoAlbumCoverImageName);
  }

  useEffect(() => {
    fetchPhotoAlbums();
    fetchPhotoPosts();
    fetchPhotoAlbum();
    document.title = 'EAK Albums';
  }, [params.id]);

  const [cardViewIsActive, setCardViewIsActive] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(false);
  const [fullImage, setFullImage] = useState([]);

  const three_col_matches = useMediaQuery('(min-width:600px)');
  const four_col_matches = useMediaQuery('(min-width:875px)');
  const five_col_matches = useMediaQuery('(min-width:1150px)');
  const six_col_matches = useMediaQuery('(min-width:1425px)');
  const seven_col_matches = useMediaQuery('(min-width:1700px)');

  const isMobile = useMediaQuery('(max-width:600px)');
  const maxColumns = Math.floor(window.innerWidth / 235);

  const openCardView = (e, index, imageId) => {
    e.preventDefault();
    setCardViewIsActive(!cardViewIsActive);
    setSelectedIndex(index);
    getFullImage(imageId);
  };

  function renderPhotos(itemData)
  {
    if(typeof itemData !== 'undefined' && itemData.length>0)
    {

      //itemData[0].rows = 2;
      //itemData[0].cols = 2;
      //itemData[0].featured = true;

      return (
        <ImageList   cols={isMobile ? 2 : maxColumns} gap={8} variant="standard">
         {/* <ImageListItem key="Subheader" cols={1}>
            <ListSubheader component="div">{photoAlbum.name}</ListSubheader>
          </ImageListItem>
           */}
          {itemData.map((item, index) => (
            <ImageListItem key={item.id} style={{ border: '5px solid #555', display: 'flex', justifyContent: 'center', alignItems: 'center' }} cols={item.cols || 1}>
              {index === selectedIndex && cardViewIsActive ? (
                <FullscreenView imagesLocations={itemData} index={index} onClose={handleCardViewClose} />
              ) : null}
              <Image
                onClick={(e) => openCardView(e, index, item.photoImageName)}
                src={item.photoImageLocation}
                alt={item.title}
                loading="eager"
                style={{ alignContent: 'middle',
                  maxWidth: '100%',
                  maxHeight: '100%',
                  '@media (minWidth: 640px)': {
                    width: '50%',
                  },
                }}
              />
              <ImageListItemBar
                title={item.title}
                /*subtitle={item.photoImageName}*/
                actionIcon={
                  <IconButton
                    onClick={() => goToImage(item.photoImageName)}
                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                    aria-label={`info about ${item.title}`}
                    alt={item.title}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      );
      
      
      
      
      
  }
  else{return(<div></div>)}
}



  async function getFullImage(id) {
    setFullImage();
    const image = await Storage.get(id);
    setFullImage(image);
  }

  function handleCardViewClose() {
    setCardViewIsActive(false);
  }

  function renderImages(item, index, className, items) {
    if (index === selectedIndex && cardViewIsActive) {
      return (<>
        <FullscreenView imagesLocations={items} index={index} onClose={handleCardViewClose} />
        <Image
          onClick={(e) => openCardView(e, index, item.photoImageName)}
          src={item.photoImageLocation}
          alt={item.title}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            '@media (minWidth: 640px)': {
              width: '50%',
            },
          }}
        />
      </>
      );
    } else {
      return (
        <Image
          onClick={(e) => openCardView(e, index, item.photoImageName)}
          src={item.photoImageLocation}
          alt={item.title}
          style={{
            maxWidth: '100%',
            maxHeight: '100%',
            '@media (minWidth: 640px)': {
              width: '50%',
            },
          }}
        />
      );
    }
  }

  async function goToImage(id) {
    navigate(`/viewPhoto/${id}`);
  }

  function renderImageCollection(items) {
    var className = cardViewIsActive ? styles.fullscreenmode : 'base-state';
    return (
      <Collection
        items={items}
        type="list"
        direction="row"
        gap="20px"
        wrap="wrap"
        style={{ width: '100%' }}
      >
        {(item, index) => (
          <Card
            key={index}
            borderRadius="medium"
            maxWidth="20rem"
            variation="outlined"
            style={{
              width: '100%',
              '@media (minWidth: 640px)': {
                width: '50%',
              },
            }}
          >
            {renderImages(item, index, className, items)}
            <View padding="xs">
              <Divider padding="xs" />
              <Heading padding="medium">{item.title}</Heading>
              <Button onClick={() => goToImage(item.photoImageName)}>
                View Image
              </Button>
              {/*
              <Button
                variation="primary"
                isFullWidth
                onClick={() =>
                  window.confirm('Are you sure you wish to delete this item?')
                    ? deletePhotoPost(item)
                    : null
                }>Delete Image</Button>*/}
            </View>
          </Card>
        )}
      </Collection>
    );
  }



  function renderPhotoPosts(parentId) {
    if (typeof parentId !== 'undefined') {
      if (parentId === null) {
        parentId = "";
      }

      return (<View>
        <View><Link href={`/photoAlbums/${parentId}`}><Button>Back to Previous Album</Button></Link>{/*<Button onClick={() => navigate(`/addPhotoToPhotoAlbum/${params.id}`)}>Add Photo to Album</Button>*/}</View>

        <View
          backgroundColor={tokens.colors.background.secondary}
          padding={tokens.space.medium}
        >
          {/*photoAlbums.map(photoAlbum => (photoAlbumCard(photoAlbum.id, photoAlbum.title, photoAlbum.title, photoAlbum.photoImageName)))*/}
          {photoPosts.length > 0 && renderPhotos(photoPosts)}
        </View></View>)
    }
  }

  function renderViewButton(id) {
    return <Link href={`/photoAlbums/${id}`}><Button variation="primary">View Album</Button></Link>
  }

  function renderDeleteButton(id) {
    //return "";
    return <Button variation="primary" onClick={() => { if (window.confirm('Are you sure you wish to delete this item?')) deletePhotoAlbum({ id }) }}>Delete Album</Button>
  }

  function photoAlbumCard(id, name, description, albumImage, parentId, numOfChildrenAlbums) {
    return (
      <Card key={id}>
        <Flex direction="row" alignItems="flex-start">
          <div className="imageDemoBlock">
            <Image
              alt={name}
              src={albumImage}
              width="150px"
              height="150px"
              style={{ width: '100%', height: 'auto' }}
            />
          </div>
          <Flex
            direction="column"
            alignItems="flex-start"
            gap={tokens.space.xs}
            style={{ width: '100%' }}
          >
            <Flex>
              <Badge size="small" variation="info">
                Plus
              </Badge>
              <Badge size="small" variation="success">
                Verified
              </Badge>
            </Flex>

            <Heading level={5}>
              {name}
            </Heading>

            <Text as="span">
              {description}
            </Text>
            {renderViewButton(id)}
            {/*renderDeleteButton(id)*/}
          </Flex>
        </Flex>
      </Card>
    );
  }


  return (<View>
    <View
      backgroundColor={tokens.colors.background.secondary}
      padding={tokens.space.medium}
    >
      <View>{photoAlbum.name}</View>
      {photoAlbums.length > 0 && <Collection
  items={photoAlbums}
  type="list"
  direction="row"
  gap="20px"
  wrap="wrap"
  style={{ width: '100%' }}
>
  {(photoAlbum, index) => (
    <Card
      key={index}
      borderRadius="medium"
      maxWidth="20rem"
      variation="outlined"
      style={{
        width: '100%',
        '@media (minWidth: 640px)': {
          width: '50%',
        },
      }}
    >
      {/* Render the photo album cover image here */}
      <View padding="xs">
      <Image
              alt={photoAlbum.name}
              src={photoAlbum.photoAlbumCoverImageLocation}
              width="200px"
              height="200px"
              style={{ width: '100%', height: 'auto' }}
            />
        <Divider padding="xs" />
        <Heading padding="xs">{photoAlbum.name}</Heading>
        {photoAlbum.description && <Text padding="xs" fontSize="medium" >{photoAlbum.description}</Text>}
        <Divider padding="xs" />
            {renderViewButton(photoAlbum.id)}
            {/*renderDeleteButton(photoAlbum.id)*/}
        {/* Add a button to navigate to the photo album here */}
      </View>
    </Card>
  )}
</Collection>}

      {/*photoAlbums.map(photoAlbum => (photoAlbumCard(photoAlbum.id, photoAlbum.name, photoAlbum.description, photoAlbum.photoAlbumCoverImageLocation, photoAlbum.photoAlbumParentPhotoAlbumId)))*/}
    </View>

    {renderPhotoPosts(photoAlbum.photoAlbumParentPhotoAlbumId)/*renderPhotos(photoPosts)*/}
  </View>
  );

}