/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listPhotoAlbumsById = /* GraphQL */ `
query listPhotoAlbumsById($id: ID) {
  listPhotoAlbums(filter: {photoAlbumParentPhotoAlbumId: {eq: $id}}) {
    nextToken
    items {
      id
      name
      description
      photoAlbumCoverImageName
      photoAlbumParentPhotoAlbumId
      parentPhotoAlbum {
        items {
          name
        }
      }
    }
  }
}
`;

export const queryPhotoAlbumList = `query QueryPhotoAlbumList {
  listPhotoAlbums {
    items {
      id
      description
      name
      photoAlbumCoverImageName
      photoAlbumParentPhotoAlbumId
    }
  }
}`;


export const querySubPhotoAlbumList = `query QuerySubPhotoAlbumList($id : ID!) {
  listPhotoAlbums(filter: {photoAlbumParentPhotoAlbumId: {eq: $id}}) {
    items {
      id
      description
      name
      photoAlbumCoverImageName
      photoAlbumParentPhotoAlbumId
      parentPhotoAlbum {
        id
        name
      }
    }
  }
}`;

export const queryPhotoAlbumById = `query QueryPhotoAlbumById($id: ID!) {
  getPhotoAlbum(id: $id) {
    id
    description
    name
    photoAlbumCoverImageName
    photoAlbumParentPhotoAlbumId
  }
}`;

export const queryNonChildPhotoAlbumList = 
`query QueryNonChildPhotoAlbumList {
  listPhotoAlbums(filter: {photoAlbumParentPhotoAlbumId: {attributeExists: false}}) {
    items {
      id
      description
      name
      photoAlbumCoverImageName
      photoAlbumParentPhotoAlbumId
    }
  }
}`;


export const getPhotoAlbum = /* GraphQL */ `
  query GetPhotoAlbum($id: ID!) {
    getPhotoAlbum(id: $id) {
      id
      name
      description
      photoAlbumCoverImageName
      parentPhotoAlbum {
        items {
          id
          name
          description
          photoAlbumCoverImageName
          createdAt
          updatedAt
          photoAlbumParentPhotoAlbumId
        }
        nextToken
      }
      photoPosts {
        items {
          id
          title
          photoImageName
          createdAt
          updatedAt
          photoAlbumPhotoPostsId
        }
        nextToken
      }
      createdAt
      updatedAt
      photoAlbumParentPhotoAlbumId
    }
  }
`;



export const listPhotoAlbumsAndParents = /* GraphQL */ `
query QueryPhotoAlbums {
  listPhotoAlbums(filter: {photoAlbumParentPhotoAlbumId: {attributeExists: false}}) {
    items {
      id
      name
      createdAt
      photoAlbumCoverImageName
      description
      updatedAt
      photoAlbumParentPhotoAlbumId
      parentPhotoAlbum {
        items {
          createdAt
          description
          id
          name
          parentPhotoAlbum {
            nextToken
          }
        }
      }
      photoPosts {
        items {
          title
        }
      }
    }
  }
}
`;


export const listPhotoAlbums = /* GraphQL */ `
  query ListPhotoAlbums(
    $filter: ModelPhotoAlbumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotoAlbums(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        photoAlbumCoverImageName
        parentPhotoAlbum {
          nextToken
        }
        photoPosts {
          nextToken
        }
        createdAt
        updatedAt
        photoAlbumParentPhotoAlbumId
      }
      nextToken
    }
  }
`;

export const listPhotoAlbumsCustom = `query MyQuery {
  listPhotoAlbums(limit: 10000) {
    items {
      id
      name
      photoAlbumParentPhotoAlbumId
      parentPhotoAlbum {
        name
        id
      }
    }
  }
}
`;

export const getPhotoPostsByPhotoAlbumId = 
`query ViewPhotosByAlbumId($id: ID!) {
  listPhotoPosts(filter: {photoAlbumPhotoPostsId: {eq: $id}}, limit: 10000) {
    items {
      createdAt
      id
      photoAlbumPhotoPostsId
      photoImageName
      title
      updatedAt
    }
  }
}`;

export const getPhotoPost = /* GraphQL */ `
  query GetPhotoPost($id: ID!) {
    getPhotoPost(id: $id) {
      id
      title
      photoImageName
      photoAlbum {
        id
        name
        description
        photoAlbumCoverImageName
        parentPhotoAlbum {
          nextToken
        }
        photoPosts {
          nextToken
        }
        createdAt
        updatedAt
        photoAlbumParentPhotoAlbumId
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          photoPostCommentsId
        }
        nextToken
      }
      createdAt
      updatedAt
      photoAlbumPhotoPostsId
    }
  }
`;
export const listPhotoPosts = /* GraphQL */ `
  query ListPhotoPosts(
    $filter: ModelPhotoPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotoPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        photoImageName
        photoAlbum {
          id
          name
          description
          photoAlbumCoverImageName
          createdAt
          updatedAt
          photoAlbumParentPhotoAlbumId
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        photoAlbumPhotoPostsId
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      photoPost {
        id
        title
        photoImageName
        photoAlbum {
          id
          name
          description
          photoAlbumCoverImageName
          createdAt
          updatedAt
          photoAlbumParentPhotoAlbumId
        }
        comments {
          nextToken
        }
        createdAt
        updatedAt
        photoAlbumPhotoPostsId
      }
      content
      createdAt
      updatedAt
      photoPostCommentsId
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        photoPost {
          id
          title
          photoImageName
          createdAt
          updatedAt
          photoAlbumPhotoPostsId
        }
        content
        createdAt
        updatedAt
        photoPostCommentsId
      }
      nextToken
    }
  }
`;

export const requestAccessToPhotos = /* GraphQL */ `
query RequestAccessToPhotos {
  requestAccessToPhotos
}`;
