import React, { useState, useEffect } from 'react';
import { requestAccessToPhotos } from '../graphql/customQueries';
import { API, Auth } from 'aws-amplify';

export function Home() {
  const [isFamilyPhotoUser, setIsFamilyPhotoUser] = useState(false);
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const [confirmationPending, setConfirmationPending] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  async function reqAccessToPhotos() {
    const confirmation = window.confirm(
      'Are you sure you want to request access to photos?'
    );

    if (confirmation) {
      setConfirmationPending(true);

      try {
        const apiData = await API.graphql({ query: requestAccessToPhotos, variables: {} });
        const response = apiData.data.requestAccessToPhotos;

        if (response.includes('success')) {
          setSuccessMessage('Access request sent successfully.  Please allow some time for approval.');
        } else {
          setErrorMessage('Access request failed. Please try again.');
        }
      } catch (error) {
        setErrorMessage('Access request failed. Please try again.');
      } finally {
        setConfirmationPending(false);
      }
    }
  }

  useEffect(() => {
    document.title = 'EAK Albums';

    async function checkIsFamilyPhotoUser() {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();

        if (currentUser) {
          setIsLoggedInUser(true);
        }

        if (currentUser && currentUser.signInUserSession.accessToken.payload['cognito:groups'].includes('FamilyPhotos')) {
          setIsFamilyPhotoUser(true);
        } else {
          setIsFamilyPhotoUser(false);
        }
      } catch (err) {
        setIsFamilyPhotoUser(false);
      }
    }

    checkIsFamilyPhotoUser();
  }, []);

  return (
    <div>
      {!isLoggedInUser ? (
        <div>
          Please login or signup for an account to view photos.
          <br />
          <br />
        </div>
      ) : null}

      {isLoggedInUser && !isFamilyPhotoUser ? (
        <div>
          If you have a login but still can't see any photos, please{' '}
          <button
            onClick={reqAccessToPhotos}
            disabled={confirmationPending}
          >
            click here
          </button>{' '}
          to send a notification to Dave for access.
          {successMessage && <div className="success-message"><br/><br/><b>{successMessage}</b></div>}
          {errorMessage && <div className="error-message"><br/><br/><b>{errorMessage}</b></div>}
          <br /><br />
        </div>
      ) : null}

      {isLoggedInUser ? (
        <div>
          <div>Please click on "Photo Albums" to view the available art.</div><br /><div>Once within an album, you can click or tap on an image to make it full screen and use the arrows at the bottom of the screen to scroll through the album.</div><br /><div>At the moment, there are only holiday cards available in the photo albums.</div>
          <br />
          <div>In the future, I will add additional art from Uncle Ed.</div>
        </div>
      ) : null}
    </div>
  );
}
